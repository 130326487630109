<template>
	<swiper
	    :modules="modules"
	    :loop="true"
	    :slides-per-view="1"
	    :space-between="50"
	    :autoplay="{ delay: 4000, disableOnInteraction: false }"
	    :navigation="navigation"
	    :pagination="{ clickable: true }"
	    :scrollbar="{ draggable: false }"
	    class="swiperBox"
	    @slideChange="onSlideChange"
	>
	    <swiper-slide>
			<img class="img-fluid" src="@/assets/index/banner1.jpg" alt="banner-image" />
		</swiper-slide>
	    <swiper-slide @click="openRecharge()">
			<img class="img-fluid" src="@/assets/index/banner2.jpg" alt="banner-image" />
		</swiper-slide>
	</swiper>
	<div class="productContent container">
		<div class="title">
			<span class="txt">产品展示</span>
		</div>
		<div class="desc">PRODUCT DISPLAY</div>
		<swiper
		    :modules="modules"
		    :loop="true"
		    :slides-per-view="1"
		    :space-between="50"
		    :autoplay="{ delay: 5000, disableOnInteraction: false }"
		    :navigation="navigation"
		    :pagination="{ clickable: true }"
		    :scrollbar="{ draggable: false }"
		    class="swiperBox"
		    @slideChange="onSlideChange"
		>
		    <swiper-slide>
				<div class="row" style="padding-bottom: 20px;">
					<div class="col-sm-6">
						<img class="img-fluid" src="@/assets/index/p1.png" alt="" />
						<div class="m-title">24小时智能盒饭机</div>
						<p class="m-info">24-hour Smart Food Box Machine</p>
					</div>
					<div class="col-sm-6">
						<img class="img-fluid" src="@/assets/index/p2.png" alt="" />
						<div class="m-title">24小时智能饮料机</div>
						<p class="m-info">24-hour Smart Drinks Machine</p>
					</div>
				</div>
			</swiper-slide>
		    <swiper-slide>
				<div class="row" style="padding-bottom: 20px;">
					<div class="col-sm-6">
						<img class="img-fluid" src="@/assets/index/p3.png" alt="" />
						<div class="m-title">24小时智能咖啡机</div>
						<p class="m-info">24-hour Smart Coffee Machine</p>
					</div>
					<div class="col-sm-6">
						<img class="img-fluid" src="@/assets/index/p4.png" alt="" />
						<div class="m-title">24小时智能零食鲜果机</div>
						<p class="m-info">24-hour Smart Snack and fresh fruit machine</p>
					</div>
				</div>
			</swiper-slide>
		</swiper>
	</div>
	
	<img class="img-fluid" src="@/assets/index/about.jpg" alt="" />
	<img class="img-fluid" src="@/assets/index/advantage.jpg" alt="" />
	<img class="img-fluid" src="@/assets/index/supply-chain1.png" alt="" />
	<img class="img-fluid" src="@/assets/index/supply-chain2.png" alt="" />
	
</template>

<script setup>
import {ref} from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { useRouter } from 'vue-router';


import 'swiper/css/pagination'
// 引入swiper核心和所需模块
import { Autoplay, Pagination, Navigation, Scrollbar } from 'swiper'

// 在modules加入要使用的模块
const modules = [Autoplay, Pagination, Navigation, Scrollbar];
// 更改当前活动swiper
const onSlideChange = (swiper) => {
  // console.log(swiper.activeIndex)
}

const router = useRouter(); 

const openRecharge = ()=>{
	router.push('/card');
}

</script>

<style lang="scss" scoped>
.productContent{
	margin-top: 40px;
	text-align: center;
	.title{
		font-weight: bold;
		font-size: 36px;
		color: #37393A;
		position: relative;
		.txt{
			position: relative;
			z-index: 2;
		}
		&::after{
			content: '';
			position: absolute;
			left: 50%;
			bottom: 10px;
			width: 110px;
			height: 10px;
			margin-left: -55px;
			background-color: #FFF200;
		}
	}
	.desc{
		margin-top: 12px;
		font-size: 14px;
		color: #FFF200;
	}
	.m-title{
		font-weight: bold;
		font-size: 30px;
		color: #37393A;
	}
	.m-info{
		margin-top: 20px;
		font-size: 16px;
		color: #37393A;
	}
}

@media (max-width: 575px){
	.productContent{
		margin-top: 20px;
		.title{
			font-size: 26px;
			&::after{
				content: '';
				position: absolute;
				left: 50%;
				bottom: 6px;
				width: 110px;
				height: 6px;
				margin-left: -55px;
			}
		}
		.desc{
			margin-top: 5px;
		}
		.m-title{
			font-size: 18px;
		}
		.m-info{
			margin-top: 5px;
			font-size: 14px;
		}
	}
	
}
</style>