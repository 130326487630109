import { createApp } from 'vue'
import App from './App.vue'

//引入轮播组件
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

//引入路由
import router from './router';
const app = createApp(App).use(router).use(VueAwesomeSwiper);

app.config.globalProperties.$Address = '15 SENOKO DRIVE #03-05 JTC FOOD HUB@SENOKO SINGAPORE 758202';
app.config.globalProperties.$phone = '6635-6666 / 8725-8992 / 9101-9378';
app.config.globalProperties.$email = 'Sales@topflightgroup.com';

app.mount('#app');
