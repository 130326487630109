<template>
	<img class="img-fluid" src="@/assets/card/banner.jpg" alt="" />
	<div class="container cardContent" ref="cardElement">
		<div class="title">
			<span class="squre"></span>
			<span class="txt">充值赠送</span>
			<span class="sub">Recharge Gifts</span>
		</div>
		<div class="row rechargeCard">
			<div class="col-sm-5">
				<img class="img-fluid" src="@/assets/card/card.png" alt="" />
			</div>
			<div class="col-sm-7">
				<div class="cardItem" v-for="(item,index) in rechargeList">
					<div class="header">
						<div class="lab">充值<span>Recharge</span></div>
						<div class="lab">赠送<span>Gifts</span></div>
						<div class="lab">合计金额<span>Total amount</span></div>
					</div>
					<div class="line">
						<div class="circle"></div>
					</div>
					<div class="bottom">
						<div class="num">S${{item.money}}</div>
						<div class="fh"> + </div>
						<div class="num">S${{item.gifts}}</div>
						<div class="fh"> = </div>
						<div class="total">S${{item.total}}</div>
					</div>
				</div>
			</div>
		</div>
		
		<img class="img-fluid" src="@/assets/card/info.png" alt="" />
		
		<div class="cardInfoCH">
			<div class="txt">开卡制卡费:S$3/张，每张卡赠送3瓶饮料，可终身循环充值使用 </div>
		</div>
		<div class="cardInfoEN">
			<p>New card cost:S$3/each card,get 3 bottles of drinks forfree for each card</p>
			<p>All cards can be recharged and used forlife.</p>
		</div>
		
		<div class="title">
			<span class="squre"></span>
			<span class="txt">选择方式</span>
			<span class="sub">Selector Mode</span>
		</div>
		<div class="selectRecharge">
			<div @click="selectRechargeType(index)" class="form-check" :class="typeIndex==index?'active':''" v-for="(item,index) in rechargeType">
				{{item.txt}} <span>{{item.txtEn}}</span>
				<img v-if="typeIndex==index" src="@/assets/card/icon.png" alt="" />
			</div>
		</div>
		<div class="title">
			<span class="squre"></span>
			<span class="txt">充值金额</span>
			<span class="sub">Recharge Amount</span>
		</div>
		<div class="rechargeAmount">
			<div @click="selectRechargeAmount(index)" class="rechargeItem" :class="amoutIndex==index?'cactive':''" v-for="(item,index) in amounList">
				<span v-if="item!='其他金额'">S${{item}}</span>
				<div v-else>
					<p>其他金额</p>
					<p>Other amount</p>
				</div>
			</div>
		</div>
		<div class="otherAmount" v-if="amoutIndex==3">
			<input type="number" v-model="amount" placeholder="Fill in the other top-up amount" />
		</div>
		
		<div class="postInfo" v-if="typeIndex==0">
			<div class="title">
				<span class="squre"></span>
				<span class="txt">充值信息</span>
				<span class="sub">Recharge Information</span>
			</div>
			<div class="infoIpt">
				<div class="lab">
					国家 <br /> <span>Nationality</span>
				</div>
				<select v-model="code">
					<option :value="item.phone_code+'-'+item.english_name" v-for="item in countryCode">
					{{item.chinese_name}} {{item.english_name}} - {{item.phone_code}}
				</option>
				</select>
			</div>
			<div class="infoIpt">
				<div class="lab">
					电话 <br /> <span>Telephone</span>
				</div>
				<input type="number" v-model="phone" placeholder="Fill in the top-up phone number" />
			</div>
			<div class="infoIpt">
				<div class="lab">
					卡号 <br /> <span>Card number</span>
				</div>
				<input type="number" v-model="card" placeholder="Fill in the recharge card number" />
			</div>
		</div>
		<div class="postInfo" v-else>
			<div class="title">
				<span class="squre"></span>
				<span class="txt">邮寄信息</span>
				<span class="sub">Mailing Information</span>
			</div>
			<div class="infoIpt">
				<div class="lab">
					姓名 <br> <span>Name</span>
				</div>
				<input type="text" v-model="name" placeholder="Fill in the recipient's name" />
			</div>
			<div class="infoIpt">
				<div class="lab">
					国家 <br /> <span>Nationality</span>
				</div>
				<select v-model="code">
					<option :value="item.phone_code+'-'+item.english_name" v-for="item in countryCode">
					{{item.chinese_name}} {{item.english_name}} - {{item.phone_code}}
				</option>
				</select>
			</div>
			<div class="infoIpt">
				<div class="lab">
					电话 <br /> <span>Telephone</span>
				</div>
				<input type="number" v-model="phone" placeholder="Fill in the recipient's phone number" />
			</div>
			<div class="infoIpt">
				<div class="lab">
					地址 <br /> <span>Address</span>
				</div>
				<textarea v-model="address" placeholder="Fill in the recipient address" cols="30" rows="2"></textarea>
			</div>
		</div>
		
		<div class="payInfo" v-if="amount">
			合计支付 <span>Sum paid</span>：
			<span v-if="typeIndex==0">
				S${{totalFee}}
			</span>
			<span v-if="typeIndex==1">
				S${{amount}} + S${{cardFee}} = S${{totalFee}}
			</span>
			
		</div>
		
		<div v-if="typeIndex==0" class="cardBtn" @click="toRecharge()">
			<div class="ch">立即充值</div>
			<div class="en">Recharge Now</div>
		</div>
		<div v-if="typeIndex==1" class="cardBtn" @click="toRecharge()">
			<div class="ch">立即购买</div>
			<div class="en">Buy Now</div>
		</div>
	</div>
	
	<div class="qrBox" v-if="qrImg">
		<div class="box">
			<div class="qr">
				<img :src="qrImg" alt="" />
			</div>
			<div class="tips">扫码支付</div>
			<div @click="closeQRCode()" class="close">
				<img src="@/assets/card/close.png" alt="" />
			</div>
		</div>
	</div>
	
</template>

<script setup>
import { computed, ref, onUnmounted} from 'vue';
import {countryCode} from '@/utils/coutryCode.js';
import QRCode from 'qrcode';
import axios from 'axios';
const rechargeList = ref([
	{
		money:50,
		gifts:5,
		total:55
	},
	{
		money:100,
		gifts:10,
		total:110
	}
]);
const rechargeType = ref([
	{
		type:'Recharge',
		txt:'充值',
		txtEn:'Recharge',
		flag:false
	},
	{
		type:'Purchase_card',
		txt:'购买卡',
		txtEn:'Purchase card',
		flag:false
	}
]);
const typeIndex = ref(0);
const amoutIndex = ref(-1);
const amounList = ref([30,50,100,'其他金额']);
const code = ref('65-Singapore');
const name = ref('');
const phone = ref('');
const address = ref('');
const card = ref('');
const amount = ref('');
const cardFee = ref(0);
const cardElement = ref(null);
const qrImg = ref('');
const orderId = ref('');
const timer = ref(null);

//计算支付总金额
const totalFee = computed(()=>Math.ceil(amount.value+cardFee.value));

//获取国家编号
const countryCodeNo = computed(()=>code.value.split('-')[0]);
//获取国家名称
const countryName = computed(()=>code.value.split('-')[1]);


// 生成支付二维码
const makeQRCode = (qrcodeUrl)=>{
	QRCode.toDataURL(qrcodeUrl).then(url=>{
		qrImg.value = url;
		queryOrder();
	})
}
//关闭支付二维码
const closeQRCode = ()=>{
	qrImg.value = '';
}

const scrollToElement = ()=>{
  if (cardElement.value) {
    cardElement.value.scrollIntoView({ behavior: 'smooth' });
  }
}

//选择充值金额
const selectRechargeAmount = (index)=>{
	amoutIndex.value = index;
	if(index!=3){
		amount.value = amounList.value[index];
	}
}
//选择充值或购买卡
const selectRechargeType = (index)=>{
	typeIndex.value = index;
	cardFee.value = index==0?0:3;
}

//点击充值
const toRecharge = ()=>{
	console.log(countryCodeNo.value);
	console.log(countryName.value);
	if(!amount.value){
		alert('Select the top-up amount');
		return false;
	}
	if(amount.value<10){
		alert('The top-up amount must be greater than 10');
		return false;
	}
	
	
	if(typeIndex.value==0){
		//立即充值
		if(!phone.value){
			alert(`Fill in the top-up phone number`);
			return false;
		}
		else if(!card.value){
			alert(`Fill in the recharge card number`);
			return false;
		}
		else{
			toRechargeCard();
		}
	}
	else{
		//立即购买
		if(!name.value){
			alert(`Please fill in the recipient's name`);
			return false;
		}
		else if(!phone.value){
			alert(`Please fill in the recipient's phone number`);
			return false;
		}
		else if(!address.value){
			alert(`Please fill in the recipient address`);
			return false;
		}
		else{
			toPurchaseCard();
		}
	}
}
//充值
const toRechargeCard = ()=>{
	axios.post('https://api.topflight.sg/appBiz/prepayorder/createOrder', {
	    "orderType":"Recharge",
		"nationality":countryName.value,
		"telephone":countryCodeNo.value+'-'+phone.value,
		"cardNumber":card.value,
		"rechargeAmount":amount.value
	})
	.then((res)=>{
		if(res.data.code==200){
			orderId.value = res.data.data.id;
			makeQRCode(res.data.data.codeUrl);
		}
	})
	.catch((error)=>{
	    console.log(error);
	});
}
//购买卡
const toPurchaseCard = ()=>{
	axios.post('https://api.topflight.sg/appBiz/prepayorder/createOrder', {
	    "orderType":"Purchase_card",
		"nationality":countryName.value,
		"userName":name.value,
		"telephone":countryCodeNo.value+'-'+phone.value,
		"address":address.value,
		"rechargeAmount":amount.value,
	})
	.then((res)=>{
	   if(res.data.code==200){
	   orderId.value = res.data.data.id;
	   makeQRCode(res.data.data.codeUrl);
	   }
	})
	.catch((error)=>{
	    console.log(error);
	});
} 

// 轮询支付状态
const queryOrder = ()=>{
	if(timer.value){
		clearInterval(timer.value);
		timer.value = null;
	}
	setTimeout(()=>{
		timer.value = setInterval(queryOrderStatus,5000);
	},5000);
}

//查询订单状态方法
const queryOrderStatus = ()=>{
	axios.post('https://api.topflight.sg/appBiz/prepayorder/queryOrder', {
	    "id":orderId.value,
	})
	.then((res)=>{
		if(res.data.code==200){
			let data = res.data.data;
			
			if(data.orderStatus!='CREATED'&&timer.value){
			   clearInterval(timer.value);
			   timer.value = null;
			   qrImg.value = '';
			   orderId.value = '';
			   if(data.orderStatus=='SUCCESS'){
					alert('支付成功');
			   }
			}
			
		   
		}
	})
	.catch((error)=>{
	    console.log(error);
	});
}

//页面卸载清楚计时器
onUnmounted(()=>{
	if(timer.value){
		clearInterval(timer.value);
	}
})

</script>

<style lang="scss" scoped>
.qrBox{
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99999;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.8);
	.box{
		position: relative;
		width: 250px;
		height: 290px;
		background-color: #fff;
		text-align: center;
		.qr{
			img{
				width: 250px;
				height: 250px;
			}
		}
		.tips{
			font-size: 18px;
		}
		.close{
			position: absolute;
			left: 50%;
			bottom: -60px;
			width: 40px;
			height: 40px;
			margin-left: -20px;
			img{
				width: inherit;
				height: inherit;
			}
		}
	}
}
.cardContent{
	margin-top: 50px;
	.selectRecharge{
		display: flex;
		justify-content: center;
		.form-check{
			display: flex;
			align-items: center;
			justify-content: flex-start;
			height: 60px;
			padding: 0 20px;
			margin-right: 20px;
			border: 1px solid #333;
			box-shadow: 0px 0px 5px 0px #999;
			font-weight: bold;
			font-size: 22px;
			cursor: pointer;
			span{
				margin-left: 10px;
				font-weight: normal;
				font-size: 18px;
			}
			img{
				width: 26px;
				height: auto;
				margin-left: 10px;
			}
			&:last-child{
				margin-right: 0;
			}
		}
		.active{
			border: 1px solid #70c155;
			box-shadow: 0px 0px 6px 0px #70c155;
			color: #70c155;
		}
	}
	.rechargeAmount{
		display: flex;
		align-items: center;
		justify-content: center;
		.rechargeItem{
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			width: 200px;
			height: 60px;
			margin-right: 20px;
			font-size: 32px;
			border:1px solid #333;
			box-shadow: 0px 0px 5px 0px #999;
			cursor: pointer;
			border-radius: 8px;
			p{
				margin: 0;
				font-size: 22px;
				&:last-child{
					font-size: 16px;
					line-height: 16px;
				}
			}
			&:last-child{
				margin-right: 0;
			}
		}
		.cactive{
			border: 1px solid #70c155;
			box-shadow: 0px 0px 6px 0px #70c155;
			color: #70c155;
		}
	}
	.otherAmount{
		overflow: hidden;
		width: 420px;
		height: 60px;
		margin: 20px auto;
		box-shadow: 0px 0px 5px 0px #999;
		border:1px solid #333;
		border-radius: 8px;
		input{
			width: 100%;
			height: 58px;
			padding: 0 20px;
			border: none;
			outline: none;
			font-size: 20px;
			box-sizing: border-box;
			text-align: center;
			font-weight: bold;
		}
	}
	.title{
		margin: 10px 0;
		.squre{
			display: inline-block;
			width: 24px;
			height: 24px;
			background: #000000;
		}
		.txt{
			margin: 0 20px 0 10px;
			font-size: 42px;
			font-weight: bold;
		}
		.sub{
			font-size: 33px;
		}
	}
	.rechargeCard{
		margin: 50px auto 30px;
		.cardItem{
			display: flex;
			flex-direction: column;
			padding: 20px;
			box-sizing: border-box;
			border: 1px solid #999;
			box-shadow: 0px 0px 6px 0px #999;
			cursor: pointer;
			&:first-child{
				margin-bottom: 20px;
			}
			.header{
				display: flex;
				justify-content: space-between;
				.lab{
					display: flex;
					align-items: flex-end;
					margin-right: 20px;
					font-size: 28px;
					font-weight: bold;
					span{
						margin-left: 10px;
						font-size:22px;
						font-weight: normal;
					}
				}
			}
			.line{
				height: 2px;
				width: 95%;
				margin: 20px auto 10px;
				background-color: #A9A9A9;
				position: relative;
				&::after{
					content: '';
					position: absolute;
					right: 0;
					top: -2px;
					width: 20px;
					height: 5px;
					background: #A9A9A9;
					border-radius:1px;
				}
				&:before{
					content: '';
					position: absolute;
					left: -19px;
					bottom: -6px;
					width: 15px;
					height: 15px;
					background: #A9A9A9;
					border: 1px solid #A9A9A9;
					border-radius: 50%;
				}
				.circle{
					position: absolute;
					left: -23px;
					bottom: -10px;
					width: 23px;
					height: 23px;
					border: 1px solid #A9A9A9;
					border-radius: 50%;;
				}
			}
			.bottom{
				display: flex;
				align-items: center;
				.num{
					width: 100px;
					font-size: 42px;
					font-weight: bold;
				}
				.fh{
					margin: 0 60px;
					color: #A9A9A9;
					font-size: 30px;
					font-weight: bold;
				}
				.total{
					font-weight: 900;
					font-size: 50px;
					background: linear-gradient(0deg, #FFE295 0%, #DCAF60 100%);
					-webkit-background-clip: text;
					background-clip: text;
					color: transparent;
				}
			}
		}
	}
	

	.cardInfoCH{
		width: 80%;
		position: relative;
		margin: 50px auto;
		font-weight: bold;
		font-size: 36px;
		color: #000000;
		line-height: 64px;
		text-align: center;
		.txt{
			position: relative;
			z-index: 2;
		}
		&::after{
			content: '';
			position: absolute;
			left: 50%;
			bottom: 10px;
			width: 94%;
			height: 15px;
			margin-left: -47%;
			background: #FFF200;
		}
	}
	.cardInfoEN{
		margin-bottom: 30px;
		font-size: 28px;
		text-align: center;
		font-weight: lighter;
		p{
			margin: 0;
		}
	}
	
	.postInfo{
		.title{
			margin: 30px 0;
		}
		.infoIpt{
			display: flex;
			width: 80%;
			margin: 10px auto;
			padding: 15px 30px;
			box-sizing: border-box;
			border: 1px solid #999;
			border-radius: 15px;
			box-shadow: 0px 0px 6px 0px #999;
			.lab{
				width: 120px;
				margin-left: 10px;
				font-size: 20px;
				span{
					color: #999;
					font-size: 16px;
				}
			}
			input,textarea,select{
				width: calc(100% - 120px);
				border: none;
				outline: none;
				font-size: 20px;
			}
		}
	}
	.payInfo{
		width: 80%;
		margin: 20px auto 0;
		padding: 20px;
		border-radius: 10px;
		background-color: #70c155;
		box-shadow: 0px 0px 6px 0px #70c155;
		font-size: 28px;
		font-weight: bold;
	}
	.cardBtn{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 600px;
		height: 100px;
		margin: 30px auto 100px;
		background: #FEF200;
		box-shadow: 5px 0px 6px 0px #1D1C00;
		border-radius: 40px 40px 40px 40px;
		cursor: pointer;
		.ch{
			font-size: 32px;
		}
		.en{
			font-size: 18px;
		}
	}
}
@media (max-width: 575px){
	.cardContent{
		margin-top: 20px;
		.selectRecharge{
			justify-content: flex-start;
			.form-check{
				height: 40px;
				padding: 0 10px;
				margin-right: 10px;
				font-size: 16px;
				span{
					margin-left: 5px;
					font-weight: normal;
					font-size: 14px;
				}
				img{
					width: 18px;
				}
			}
		}
		.rechargeAmount{
			margin: 15px 0;
			.rechargeItem{
				width: 75px;
				height: 40px;
				margin-right: 10px;
				font-size: 18px;
				p{
					margin: 0;
					font-size: 14px;
					text-align: center;
					&:last-child{
						font-size: 12px;
						line-height: 12px;
					}
				}
				&:last-child{
					flex: 1;
				}
			}
			
		}
		.otherAmount{
			overflow: hidden;
			width: calc(100% - 10px);
			height: 40px;
			margin: 10px 5px;
			input{
				width: 100%;
				height: 38px;
				font-size: 16px;
			}
		}
		.title{
			.squre{
				width: 12px;
				height: 12px;
			}
			.txt{
				font-size: 20px;
			}
			.sub{
				font-size: 16px;
			}
		}
		
		.rechargeCard{
			margin: 30px auto 15px;
			.cardItem{
				padding:10px 20px;
				.header{
					.lab{
						align-items: flex-start;
						flex-direction: column;
						font-size: 14px;
						span{
							margin-left: 0;
							font-size:12px;
						}
					}
				}
				.line{
					height: 2px;
					width: 95%;
					margin: 20px auto 10px;
					background-color: #A9A9A9;
					position: relative;
					&::after{
						content: '';
						position: absolute;
						right: 0;
						top: -2px;
						width: 16px;
						height: 5px;
						background: #A9A9A9;
						border-radius:1px;
					}
					&:before{
						content: '';
						position: absolute;
						left: -15px;
						bottom: -5px;
						width: 11px;
						height: 11px;
						background: #A9A9A9;
						border: 1px solid #A9A9A9;
						border-radius: 50%;
					}
					.circle{
						position: absolute;
						left: -19px;
						bottom: -9px;
						width: 19px;
						height: 19px;
						border: 1px solid #A9A9A9;
						border-radius: 50%;;
					}
				}
				.bottom{
					.num{
						width: 35px;
						font-size: 18px;
						font-weight: bold;
					}
					.fh{
						margin: 0 30px;
						font-size: 16px;
					}
					.total{
						font-size: 16px;
					}
				}
			}
		}
		
		.cardInfoCH{
			margin: 30px auto;
			font-size: 18px;
			line-height: 1.8em;
			&::after{
				height: 8px;
			}
		}
		.cardInfoEN{
			font-size: 16px;
		}
		
		.postInfo{
			.title{
				margin: 20px 0;
			}
			.infoIpt{
				width: 100%;
				padding:10px 15px;
				border-radius: 10px;
				.lab{
					width: 80px;
					margin-left: 8px;
					font-size: 14px;
					span{
						font-size: 12px;
					}
				}
				input,textarea,select{
					width: calc(100% - 80px);
					font-size: 14px;
				}
			}
		}
		
		.payInfo{
			width: 100%;
			padding:15px 10px;
			border-radius: 10px;
			background-color: #70c155;
			box-shadow: 0px 0px 6px 0px #70c155;
			font-size: 18px;
			span{
				font-size: 16px;
				font-weight: normal;
			}
		}
		
		.cardBtn{
			width: 90%;
			height: 60px;
			margin: 30px auto 100px;
			.ch{
				font-size: 18px;
				font-weight: bold;
			}
			.en{
				font-size: 14px;
			}
		}
		
	}
	
}
</style>