<template>
	<footer class="footerBox">
		<div class="con">
			<div class="container">
				<div class="row">
					<div class="col-md-6 pl-5">
						<div class="lab-title">Address</div>
						<div class="desc">{{address}}</div>
					</div>
					<div class="col-md-3 pl-5 line">
						<div class="lab-title">Contact</div>
						<div class="desc">{{phone}}</div>
					</div>
					<div class="col-md-3 pl-5 line">
						<div class="lab-title">Email</div>
						<div class="desc">{{email}}</div>
					</div>
				</div>
			</div>
		</div>
	</footer>
	<a href="whatsapp://send?phone=+6591019378&text=你好" class="whatsapp">
		<img class="icon" src="@/assets/contact/whatsapp.png" alt="Online Service" />
		<div class="txt">
			在线客服 <br />
			Online Service
		</div>
	</a>
</template>

<script setup>
import {getCurrentInstance} from 'vue';

const { appContext } = getCurrentInstance();
const address = appContext.config.globalProperties.$Address;
const phone = appContext.config.globalProperties.$phone;
const email = appContext.config.globalProperties.$email;

</script>

<style lang="scss" scoped>
	.whatsapp{
		display: block;
		position: fixed;
		right: 10px;
		bottom: 150px;
		z-index: 99;
		text-align: center;
		text-decoration: none;
		.icon{
			width: 60px;
			height: auto;
		}
		.txt{
			margin-top: 10px;
			padding: 5px 10px;
			box-sizing: border-box;
			background-color: #70c155;
			border-radius: 10px;
			color: #fff;
			font-size: 14px;
		}
	}
	.footerBox{
		width: 100%;
		padding-bottom:70px;
		background-image:url('@/assets/footer/footer_bg.jpg');
		background-position: center;
		background-size: cover;
		box-sizing:border-box;
		.con{
			padding: 70px 0;
			box-sizing:border-box;
			border-bottom:1px solid #333;
			.lab-title{
				position:relative;
				padding-bottom: 10px;
				margin-bottom: 20px;
				font-size:18px;
				font-weight:bold;
				color:#fff;
				&:after{
					content:'';
					position:absolute;
					left:0;
					bottom:0;
					width: 40px;
					height:3px;
					background-color:#fff;
				}
			}
			.desc{
				font-size:18px;
				color:#999;
			}
			.pl-5{
				padding-left: 50px;
			}
			.line{
				position: relative;
				&::after{
					content:'';
					position:absolute;
					left:0;
					top:0;
					width: 1px;
					height:100%;
					background-color:#333;
				}
			}
		}
	}
	@media (max-width: 575px){
		.whatsapp{
			bottom: 80px;
			.icon{
				width: 40px;
				height: auto;
			}
			.txt{
				padding: 5px;
				font-size: 12px;
			}
		}
		
		.footerBox{
			padding-bottom:30px;
			.con{
				padding: 30px 0;
				.lab-title{
					font-size:16px;
					margin-bottom: 15px;
				}
				.desc{
					font-size:14px;
					margin-bottom: 15px;
				}
				.pl-5{
					padding-left: 30px;
				}
			}
		}
	}
</style>