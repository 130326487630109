<template>
	<div>
		<img class="img-fluid" src="@/assets/points/pc_1.jpg" alt="" />
	</div>
	<div style="background-color: #f84e34;padding-top: 30px;">
		<div class="search-btn" @click="clickSearchBtn()">积分查询</div>
		<div class="container" style="padding: 0;">
			<div class="pc-content">
				<img class="img-fluid" src="@/assets/points/pc_2.jpg" alt="" />
				<img class="img-fluid" src="@/assets/points/pc_3.jpg" alt="" />
				<img class="img-fluid" src="@/assets/points/pc_4.jpg" alt="" />
			</div>
			<div class="m-content">
				<img class="img-fluid" src="@/assets/points/m_2.jpg" alt="" />
				<img class="img-fluid" src="@/assets/points/m_3.jpg" alt="" />
				<img class="img-fluid" src="@/assets/points/m_4.jpg" alt="" />
			</div>
			
			<div style="padding: 15px;box-sizing: border-box;">
				<div class="goods-box row">
					<div v-for="(item,index) in goodsArr" class="goods-content col-md-6 bdBottom">
						<img class="cover" :src="item.imageUrl" alt="" />
						<div class="exchange-btn" @click="toExchange(item)">立即兑换</div>
						<div class="point-tips">
							<img class="point-bg" :class="item.goodsStatus=='上架'?'':'bg-gray'" src="@/assets/points/point_box.png" alt="" />
							<div class="point-fee">
								<div class="fee" v-if="item.goodsStatus=='上架'">{{item.coinAmount}}</div>
								<div class="label">{{item.goodsStatus=='上架'?'积分':'已下架'}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div style="height: 60px;"></div>
		</div>
	</div>
	<div v-if="showModalStatus" class="point-modal">
		<div class="search-content">
			<div @click="hideSearch()" class="close">
				<img src="@/assets/points/close.png" alt="" />
			</div>
			<div class="left-c">
				<img v-if="searchStatus" class="img" src="@/assets/points/02.png" alt="" />
				<img v-else class="img" src="@/assets/points/01.png" alt="" />
			</div>
			<div class="right-c">
				<div class="s-title">{{searchStatus?'查询结果':'积分查询'}}</div>
				<div v-if="!searchStatus" class="ipt">
					<img class="icon" src="@/assets/points/user.png" alt="" />
					<input type="text" v-model="cardVal" placeholder="请输入卡号" />
				</div>
				<div class="s-content" v-else>
					<div class="result">用户卡号：{{searchInfo.accountName}}</div>
					<div class="result">积分余额：{{searchInfo.usableAmount}}</div>
					<div class="result">兑换记录：{{searchInfo.exchangeTimes}}</div>
				</div>
				<div class="s-btn" @click="searchFn()">{{searchStatus?'返回查询':'立即查询'}}</div>
			</div>
		</div>
	</div>
	
	<div v-show="exchangeStatus" class="point-modal">
		<div class="exchange-content" style="flex-direction: column;">
			<div @click="exchangeStatus=false" class="close">
				<img src="@/assets/points/close.png" alt="" />
			</div>
			<div class="exchange-con">
				<div class="s-title">兑换信息</div>
				<div class="ipt">
					<div class="lab">卡号：</div>
					<input type="text" v-model="exCard" placeholder="请输入卡号" />
				</div>
				<div class="ipt">
					<div class="lab">数量：</div>
					<input type="number" v-model="exNum" placeholder="请输入兑换数量" />
				</div>
				<div class="ipt">
					<div class="lab">收货人：</div>
					<input type="text" v-model="exName" placeholder="请输入收货人" />
				</div>
				<div class="ipt">
					<div class="lab">手机号：</div>
					<input type="text" v-model="exMobile" placeholder="请输入收货人手机号" />
				</div>
				<div class="area">
					<div class="lab">地址：</div>
					<textarea v-model="exAddr" cols="20" rows="2" placeholder="请输入收货人地址"></textarea>
				</div>
				<div class="area">
					<div class="lab">备注：</div>
					<textarea v-model="exRemark" cols="20" rows="1" placeholder="备注"></textarea>
				</div>
				
				<div class="s-btn" @click="exchangeFn()">立即兑换</div>
				
			</div>
		</div>
	</div>
	
</template>

<script setup>
import { ref,onMounted } from "vue";
import axios from 'axios';

let showModalStatus = ref(false);
let searchStatus = ref(false);
let cardVal = ref('');
let goodsArr = ref([]);
let searchInfo = ref({});

let exCard = ref('');
let exNum = ref('');
let exName = ref('');
let exMobile = ref('');
let exAddr = ref('');
let exRemark = ref('');

let goodsInfo = ref({});
let exchangeStatus = ref(false);

const clickSearchBtn = ()=>{
	showModalStatus.value = true;
}

onMounted(()=>{
	axios.get('https://api.topflight.sg/appBiz/goods/page')
	.then((res)=>{
		if(res.data.code==200){
			let data = res.data.data;
			goodsArr.value = data.records;
		}
	})
	.catch((error)=>{
	    console.log(error);
	});
	
	// axios.get('https://api.topflight.sg/appBiz/exchangerecord/page?accountName=1234567')
	// .then((res)=>{
	// 	if(res.data.code==200){
	// 		let data = res.data.data;
	// 		console.log(data);
	// 	}
	// })
	// .catch((error)=>{
	//     console.log(error);
	// });
})

const hideSearch = ()=>{
	showModalStatus.value = false;
	searchStatus.value = false;
}

const hideExchange = ()=>{
	console.log(1);
}

const exchangeFn = ()=>{
	if(!exCard.value){
		alert('请输入兑换卡号')
	}
	else if(!exNum.value || exNum.value<=0){
		alert('请检查输入的兑换数量')
	}
	else if(!exName.value){
		alert('请输入收货人')
	}
	else if(!exMobile.value){
		alert('请输入手机号')
	}
	else if(!exAddr.value){
		alert('请输入地址')
	}
	else{
		exNum.value = Math.ceil(exNum.value);
		let costFee = Math.floor(goodsInfo.value.coinAmount*exNum.value);
		if(confirm(`确定要消耗${costFee}积分进行兑换吗？`)){
			axios.get('https://api.topflight.sg/appBiz/cardaccount/accountDetail?accountName='+exCard.value)
			.then((res)=>{
				if(res.data.code==200){
					if(res.data.data.usableAmount>=costFee){
						exchangePoint();
					}
					else{
						alert(`此卡号积分已不足${costFee}`);
					}
					
				}
				else{
					alert(res.data.msg);
				}
			})
			.catch((error)=>{
			    console.log(error);
			});
		}
		
		
	}
	
	
}

const exchangePoint = ()=>{
	axios.post('https://api.topflight.sg/appBiz/exchangerecord/addRecord', {
	    "goodsId": goodsInfo.value.id,
		"accountName": exCard.value,
		"buyNumber": exNum.value,
		"userName": exName.value,
		"userMobile": exMobile.value,
		"userAddress": exAddr.value,
		"exchangeRemark": exRemark.value
	})
	.then((res)=>{
		console.log(res);
		if(res.data.code==200){
			alert('兑换成功'); 
			exchangeStatus.value = false;
		}
		else{
			alert(res.data.msg);
		}
	})
	.catch((error)=>{
	    console.log(error);
	});
}


const searchFn = ()=>{
	if(searchStatus.value){
		searchStatus.value = false;
	}
	else{
		if(cardVal.value){
			axios.get('https://api.topflight.sg/appBiz/cardaccount/accountDetail?accountName='+cardVal.value)
			.then((res)=>{
				if(res.data.code==200){
					searchStatus.value = true;
					searchInfo.value = res.data.data;
				}
				else{
					alert(res.data.msg);
				}
			})
			.catch((error)=>{
			    console.log(error);
			});
			
		}
		else{
			alert('请输入卡号！');
		}
	}
}

const toExchange = (info)=>{
	goodsInfo.value = info;
	exchangeStatus.value = true;
	
}
</script>

<style lang="scss" scoped>
	.point-modal{
		position: fixed;
		top: 0;
		left: 0;
		z-index: 99999;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: rgba(0, 0, 0, 0.3);
		.exchange-content{
			position: relative;
			width: 800px;
			height: 600px;
			display: flex;
			align-items: center;
			justify-content: center;
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
			border-radius: 25px;
			background-color: #fff;
			overflow: hidden;
			.close{
				position: absolute;
				right: 35px;
				top: 10px;
				padding: 15px;
				width: 36px;
				cursor: pointer;
				img{
					width: inherit;
				}
			}
			.exchange-con{
				flex: 1;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				height: inherit;
				background-color: #fff;
				.s-title{
					position: relative;
					font-weight: bold;
					font-size: 24px;
					&::after{
						content: '';
						position: absolute;
						left: 50%;
						bottom: -5px;
						width: 40px;
						height: 4px;
						margin-left: -20px;
						background-color: #fcae41;
					}
				}
				.ipt,.area{
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 300px;
					height: 48px;
					margin-top: 20px;
					border: 1px solid #999;
					padding:0 15px;
					box-sizing: border-box;
					.icon{
						width: 20px;
					}
					.lab{
						width: 70px;
					}
					input,textarea{
						border: none;
						outline: none;
						flex: 1;
					}
				}
				.area{
					height: 60px;
				}
				.s-content{
					width: 300px;
					margin-top: 30px;
					.result{
						color: #999;
						font-size: 16px;
						line-height: 28px;
					}
				}
				.s-btn{
					display: flex;
					align-items: center;
					justify-content: center;
					width: 300px;
					height: 48px;
					margin-top: 30px;
					background: linear-gradient(#fdb94c,#fd8419);
					color: #fff;
					font-size: 20px;
					cursor: pointer;
				}
			}
		}
		.search-content{
			position: relative;
			width: 1000px;
			height: 600px;
			display: flex;
			align-items: center;
			justify-content: center;
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
			border-radius: 25px;
			background-color: #fff;
			overflow: hidden;
			.close{
				position: absolute;
				right: 35px;
				top: 10px;
				padding: 15px;
				width: 36px;
				cursor: pointer;
				img{
					width: inherit;
				}
			}
			.left-c{
				display: flex;
				align-items: center;
				justify-content: center;
				width: 500px;
				height: inherit;
				background-color: #fcae41;
				text-align: center;
				.img{
					width: 400px;
				}
			}
			.right-c{
				flex: 1;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				height: inherit;
				background-color: #fff;
				.s-title{
					position: relative;
					font-weight: bold;
					font-size: 24px;
					&::after{
						content: '';
						position: absolute;
						left: 50%;
						bottom: -5px;
						width: 40px;
						height: 4px;
						margin-left: -20px;
						background-color: #fcae41;
					}
				}
				.ipt{
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 300px;
					min-height: 48px;
					margin-top: 30px;
					border: 1px solid #999;
					padding:0 15px;
					box-sizing: border-box;
					.icon{
						width: 20px;
					}
					input{
						border: none;
						outline: none;
						flex: 1;
					}
				}
				.s-content{
					width: 300px;
					margin-top: 30px;
					.result{
						color: #999;
						font-size: 16px;
						line-height: 28px;
					}
				}
				.s-btn{
					display: flex;
					align-items: center;
					justify-content: center;
					width: 300px;
					height: 48px;
					margin-top: 30px;
					background: linear-gradient(#fdb94c,#fd8419);
					color: #fff;
					font-size: 20px;
					cursor: pointer;
				}
			}
		}
	}
	
	
	.search-btn{
		position: relative;
		top: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 280px;
		height: 68px;
		margin: 0 auto 30px;
		border-radius: 34px;
		background: linear-gradient(#fdb94c,#fd8419);
		text-align: center;
		font-size: 28px;
		color: #fff;
		cursor: pointer;
	}
	.pc-content{
		display: block;
	}
	.m-content{
		display: none;
	}
	.goods-box{
		max-width: 1000px;
		margin: 0 auto;
		border-radius: 25px;
		background-color: #fff;
		text-align: center;
		.cover{
			width: auto;
			height: 200px;
		}
		.bdBottom{
			border-bottom: 1px dashed #f84e34;
		}
		.goods-content{
			position: relative;
			display: inline-block;
			padding: 25px;
			box-sizing: border-box;
			&:nth-child(2n+1){
				border-right: 1px dashed #f84e34;
			}
			&:last-child{
				border-right: none;
			}
			
			.exchange-btn{
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100px;
				height: 30px;
				margin: 20px auto 0;
				font-size: 16px;
				border: 1px solid #333;
				border-radius: 15px;
				cursor: pointer;
			}
			.point-tips{
				position: absolute;
				right: 30px;
				top: 30px;
				width: 80px;
				height: 80px;
				.point-bg{
					width: inherit;
					height: inherit;
				}
				.bg-gray{
					filter: grayscale(100%);
				}
				.point-fee{
					position: absolute;
					top: 0;
					right: 0;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					width: 80px;
					height: 80px;
					z-index: 2;
					color: #fff;
					.fee{
						font-size: 14px;
					}
					.label{
						font-size: 12px;
					}
				}
			}
		}
	}
	@media (max-width: 575px){
		.pc-content{
			display: none;
		}
		.m-content{
			display: block;
			padding: 0 10px;
		}
		.search-btn{
			width: 200px;
			height: 48px;
			font-size: 20px;
		}
		.goods-box{
			.goods-content{
				.point-tips{
					right: 10px;
					top: 10px;
				}
				&:nth-child(2n+1){
					border-right:none;
				}
			}
		}
		
		
		.point-modal{
			position: fixed;
			top: 0;
			left: 0;
			z-index: 99999;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: rgba(0, 0, 0, 0.3);
			padding: 15px;
			box-sizing: border-box;
			
			.search-content{
				height: 400px;
				.close{
					padding: 15px;
					width: 24px;
					img{
						width: inherit;
					}
				}
				.left-c{
					display: none;
				}
				.right-c{
					
					.s-title{
						
						&::after{
							
							
						}
					}
					.ipt{
						
						.icon{
							
						}
						input{
							
						}
					}
					.s-content{
						
						.result{
							
						}
					}
					.s-btn{
						
					}
				}
			}
		}
	}
</style>