<template>
	<div class="contactBanner"></div>
	<div class="container contactBox">
		<div class="row">
			<div class="col-md-4" style="display: flex;flex-direction: column;padding: 0;">
				<div class="leftTop">
					<div class="en">CONTACT US</div>
					<div class="ch">联系我们</div>
				</div>
				<div class="leftBottom">
					<div class="leftInfo">
						<img class="icon" src="@/assets/contact/address.png" alt="" />
						{{address}}
					</div>
					<div class="leftInfo">
						<img class="icon" src="@/assets/contact/phone.png" alt="" />
						{{phone}}
					</div>
					<div class="leftInfo">
						<img class="icon" src="@/assets/contact/email.png" alt="" />
						{{email}}
					</div>
				</div>
			</div>
			<div class="col-md-8" style="padding: 0;">
				<div id="map" style="width: 100%; height: 400px;"></div>
			</div>
		</div>
	</div>
</template>

<script setup>
import {ref,onMounted,getCurrentInstance} from 'vue';
import AMapLoader from '@amap/amap-jsapi-loader';
import mapIcon from '@/assets/contact/location.png';

const { appContext } = getCurrentInstance();
const address = appContext.config.globalProperties.$Address;
const phone = appContext.config.globalProperties.$phone;
const email = appContext.config.globalProperties.$email;
const map = ref(null);

onMounted(() => {
	AMapLoader.load({
		key:'95e14ae7df57e67d8d062c14ee3922d6',
		version: '2.0',
		plugins: ['AMap.Geolocation', 'AMap.Marker']
	}).then((aMap)=>{
		map.value = new AMap.Map('map', {
			center:[103.908764,1.367352],
			zoom:9,
			scale:2
		});
		
		const icon = new AMap.Icon({
		  size: new AMap.Size(50, 50),
		  image:mapIcon,
		  imageSize: new AMap.Size(50, 50) 
		});
		
		const marker = new AMap.Marker({
			position: new AMap.LngLat(103.908764,1.367352),
			title: 'TOPFLIGHT GROUP PTE.LTD',
			map:map.value,
			icon:icon
		});
		
		marker.setMap(map.value);
		
	}).catch(e => {
        console.log(e);
    });
	
	return {
	      map
	};
})
</script>

<style lang="scss" scoped>
	.contactBanner{
		width: 100%;
		height: 300px;
		background-image: url('../assets/contact/banner.jpg');
		background-position: center;
		background-size: cover;
	}
	.contactBox{
		padding: 50px 0 100px;
		box-sizing: border-box;
		.leftTop{
			padding: 20px 30px;
			box-sizing: border-box;
			background-color: #70c155;
			color: #fff;
			.en{
				font-size: 26px;
			}
			.ch{
				font-size: 16px;
			}
		}
		.leftBottom{
			flex: 1;
			background-color: #343434;
			padding: 20px;
			box-sizing: border-box;
			.leftInfo{
				margin: 30px 0;
				color: #fff;
				font-size: 14px;
				&:first-child{
					margin-bottom: 60px;
				}
				.icon{
					width: 24px;
					margin-right: 8px;
				}
			}
		}
	}
	
	
	@media (max-width: 575px){
		.contactBanner{
			height: 150px;
		}
		.contactBox{
			padding: 0;
		}
	}
</style>