<template>
	<img class="img-fluid" src="@/assets/about/banner.jpg" alt="" />
	<div class="container abountContent">
		<div class="a-title">
			<router-link to="/about/intro">公司简介</router-link>
			<router-link class="link" to="/about/show">形象展示</router-link>
			<router-link class="link" to="/about/target">运营目标</router-link>
			<router-link class="link" to="/about/partner">合作伙伴</router-link>
		</div>
		<div class="a-content">
			<router-view></router-view>
		</div>
	</div>
</template>

<script setup>
	import {onMounted} from 'vue';
	import { useRouter } from 'vue-router';
	const router = useRouter();
	
	onMounted(()=>{
		router.push('/about/intro');
	})
</script>

<style lang="scss" scoped>
	.abountContent{
		.a-title{
			text-align: center;
			a{
				text-decoration: none;
				color: #37393A;
				font-size: 20px;
				font-weight: bold;
				padding: 0 30px;
			}
			.router-link-active {
				color: #89cd60;
			}
			.link{
				position: relative;
				&::after{
					content: '';
					position: absolute;
					width: 2px;
					height: 20px;
					left: 0;
					bottom: 2px;
					background-color: #37393A;
				}
			}
		}
		.a-content{
			text-align: center;
			padding: 50px 0 100px;
		}
	}
	
	@media (max-width: 575px){
		.abountContent{
			.a-title{
				a{
					font-size: 14px;
					padding: 0 10px;
				}
				.link{
					position: relative;
					&::after{
						content: '';
						position: absolute;
						width: 2px;
						height: 16px;
						left: 0;
						bottom: 2px;
					}
				}
			}
			.a-content{
				padding: 20px 0 30px;
			}
		}
	}
	
</style>