<template>
	<swiper
	    :modules="modules"
	    :loop="true"
	    :slides-per-view="1"
	    :space-between="50"
	    :autoplay="{ delay: 4000, disableOnInteraction: false }"
	    :navigation="navigation"
	    :pagination="{ clickable: true }"
	    :scrollbar="{ draggable: false }"
	    class="swiperBox"
	>
	    <swiper-slide>
			<img class="img-fluid" src="@/assets/products/banner1.jpg" alt="banner-image" />
		</swiper-slide>
	    <swiper-slide>
			<img class="img-fluid" src="@/assets/products/banner2.jpg" alt="banner-image" />
		</swiper-slide>
		<swiper-slide>
			<img class="img-fluid" src="@/assets/products/banner3.jpg" alt="banner-image" />
		</swiper-slide>
		<swiper-slide>
			<img class="img-fluid" src="@/assets/products/banner4.jpg" alt="banner-image" />
		</swiper-slide>
	</swiper>
	<div class="container productContent">
		<div class="p-title">
			<router-link to="/products/food">盒饭机</router-link>
			<router-link class="link" to="/products/drinks">饮料机</router-link>
			<router-link class="link" to="/products/coffee">咖啡机</router-link>
			<router-link class="link" to="/products/snack">零食鲜果机</router-link>
		</div>
		<div class="p-content">
			<router-view></router-view>
		</div>
	</div>
</template>

<script setup>
import {ref,onMounted} from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { useRouter } from 'vue-router';

// 引入swiper样式（按需导入）
import 'swiper/css/pagination'
// 引入swiper核心和所需模块
import { Autoplay, Pagination, Navigation, Scrollbar } from 'swiper'

// 在modules加入要使用的模块
const modules = [Autoplay, Pagination, Navigation, Scrollbar];

const router = useRouter();

onMounted(()=>{
	router.push('/products/food');
})
</script>

<style lang="scss" scoped>
	.productContent{
		margin-top: 30px;
		.p-title{
			text-align: center;
			a{
				text-decoration: none;
				color: #37393A;
				font-size: 20px;
				font-weight: bold;
				padding: 0 30px;
			}
			.router-link-active {
				color: #89cd60;
			}
			.link{
				position: relative;
				&::after{
					content: '';
					position: absolute;
					width: 2px;
					height: 20px;
					left: 0;
					bottom: 2px;
					background-color: #37393A;
				}
			}
		}
		.p-content{
			text-align: center;
			padding: 50px 0;
		}
	}
	
	@media (max-width: 575px){
		.productContent{
			.p-title{
				a{
					font-size: 14px;
					padding: 0 10px;
				}
				.link{
					position: relative;
					&::after{
						content: '';
						position: absolute;
						width: 2px;
						height: 16px;
						left: 0;
						bottom: 2px;
					}
				}
			}
			.p-content{
				padding: 20px 0;
			}
		}
	}
	
</style>